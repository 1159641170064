<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center">
      <TotalLogoStamp
        v-if="getPlatform() === 'total'"
        :image="require('../../assets/img/PNG/total-express-logo.png')"
        width="auto"
        height="170"
      />
      <img
        v-else-if="getPlatform() === 'e-cross'"
        :src="require('../../assets/img/PNG/e-cross-logo-circle.png')"
        height="300"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'anjun'"
        :src="require('../../assets/img/PNG/anjun-logo.png')"
        height="150"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'tiendamia'"
        :src="require('../../assets/img/PNG/tiendamia-logo.png')"
        height="150"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'mirakl'"
        :src="require('../../assets/img/PNG/mirakl-logo.png')"
        height="80"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'skypostal'"
        :src="require('../../assets/img/PNG/skypostal-logo.png')"
        height="80"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'jt'"
        :src="require('../../assets/img/PNG/jt-logo.png')"
        height="80"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'correios'"
        :src="require('../../assets/img/PNG/correios-logo.png')"
        height="80"
        width="auto"
      />
      <img
        v-else-if="getPlatform() === 'shopee'"
        :src="require('../../assets/img/PNG/shopee-logo.png')"
        height="80"
        width="auto"
      />
      <AsiaLogoStamp
        v-else-if="getPlatform() === 'asia'"
        class=""
        width="auto"
        height="170"
      />
      <div v-else id="loading-text">
        <IconLogo class="" width="555" height="170" />
      </div>
    </div>
  </div>
</template>

<script>
import IconLogo from "@/components/icons/IconLogo.vue";
import TotalLogoStamp from "@/components/icons/TotalLogoStamp.vue";
import AsiaLogoStamp from "@/components/icons/AsiaLogoStamp.vue";

export default {
  name: "LoadingPage",
  components: { IconLogo, TotalLogoStamp, AsiaLogoStamp },
  methods: {
    getPlatform() {
      const platform = this.$helpers.getPlatform();
      if (platform) return platform;
      return "nobordist";
    },
  },
};
</script>

<style scoped></style>
