<template>
  <div
    class="container-fluid"
    :class="
      getCurrentPlatform() === '/asia-login' ? 'bg-gray-20' : 'login-page'
    "
  >
    <LoadingPageLogin class="login-wrapper" />
    <div class="row d-flex justify-content-center">
      <div class="col-10 col-lg-4 col-xl-2 px-4 py-3">
        <div class="row align-items-center" style="height: calc(100vh - 150px)">
          <div class="col">
            <!-- n~ao esta sendo utilizado
            <error-alert
              v-for="(error, index) in errors"
              v-bind:key="index"
              @close="removeFromErrors(errors, index)"
            >
            </error-alert> 
            -->
            <form v-on:submit.prevent="resetPassword">
              <div class="form-group">
                <input
                  type="email"
                  v-model="registered_email"
                  class="form-control"
                  :placeholder="$t('passwordNewPage.registeredEmail')"
                  required
                />
              </div>

              <div class="form-group">
                <input
                  :type="passwordOrText"
                  v-model="password"
                  class="form-control"
                  :placeholder="$t('passwordNewPage.newPassword')"
                  required
                  :class="{ 'is-invalid': errors['password'].length > 0 }"
                />

                <div
                  class="invalid-feedback text-left"
                  v-for="(error, index) in errors['password']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>

              <div class="form-group">
                <input
                  v-model="password_confirmation"
                  class="form-control"
                  placeholder="Confirm new password"
                  :type="passwordOrText"
                  required
                />
              </div>

              <button
                type="submit"
                class="button-secondary float-right ml-3 btn-block"
              >
                <span v-if="loading">
                  <span class="spinner-border spinner-border-sm"></span> Loading
                </span>

                <span v-if="!loading">Change Password</span>
              </button>
              <label
                v-if="passwordOrText === 'text'"
                for="password"
                @click="passwordOrText = 'password'"
              >
                <NbIcon
                  class="display-flex-right"
                  icon="eye"
                  :attributes="{
                    width: '1rem',
                    height: '1rem',
                  }"
                />
                Hide Password
              </label>
              <label v-else for="password" @click="passwordOrText = 'text'">
                <NbIcon
                  icon="eye-off"
                  :attributes="{
                    width: '1rem',
                    height: '1rem',
                  }"
                />
                Show Password
              </label>
            </form>
            <a
              :href="getCurrentPlatform()"
              class="button-danger float-right mt-3"
              >Cancel</a
            >
            <!-- <button @click="$router.push('/login')" class="button-danger float-right mt-3">Cancel</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordService from "../services/PasswordService";
import LoadingPageLogin from "@/components/loadings/LoadingPageLogin.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

const passwordService = new PasswordService();

export default {
  name: "passwordNew",
  components: {
    LoadingPageLogin,
    NbIcon,
  },
  data: function () {
    return {
      errors: {
        password: [],
      },
      registered_email: "",
      password: "",
      password_confirmation: "",
      loading: false,
      passwordOrText: "password",
      uglyPassword: false,
      pwErrorMsg: "",
    };
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.errors["password"] = [];
      if (this.password != this.password_confirmation) {
        this.errors["password"].push(
          "The password and password confirmation doesn't match"
        );
      }
      if (this.password.match(/\s/)) {
        this.errors["password"].push("should not contain white space");
      }
      if (this.password.length < 11) {
        this.errors["password"].push("at least 11 characters");
      }
      if (!this.password.match(/[a-z]/)) {
        this.errors["password"].push("at least 1 lowercase letter");
      }
      if (!this.password.match(/[A-Z]/)) {
        this.errors["password"].push("at least 1 uppercase letter");
      }
      if (!this.password.match(/\d/)) {
        this.errors["password"].push("at least 1 number");
      }
      if (!this.password.match(/\W/)) {
        this.errors["password"].push("at least 1 special character");
      }
      if (this.errors["password"].length > 0) {
        this.loading = false;
        return;
      }

      let data = {
        email: this.registered_email,
        password: this.password,
        token: this.$route.query.token,
        platform: this.$route.query.platform,
      };
      passwordService
        .resetPassword(data)
        .then(() => {
          const prefix = this.$helpers.getPlatform("prefix");

          this.$router.push(`/${prefix}-login`).then(() => {
            this.$helpers.toast(
              this.$t("passwordNewPage.passwordChanged"),
              "success"
            );
          });
        })
        .catch((error) => {
          let errors = this.$helpers.extractErrorMessages(error);

          this.loading = false;

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          window.scrollTo(0, 0);
        });
    },
    getCurrentPlatform() {
      let prefix = this.$helpers.getPlatform("prefix");

      return `/${prefix}-login`;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-banner {
  height: 100vh;
  background-image: url(../assets/img/login-banner.jpg);
  background-size: cover;
}
.login-wrapper {
  animation: animate-login-screen 2.3s ease-in;
  animation-fill-mode: forwards;
  width: 345px;
}
.login-page {
  height: 100vh;
  overflow: hidden;
  background: var(--navBg);
}
@keyframes animate-login-screen {
  0% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  50% {
    margin-top: 20%;
    height: 100vh;
    transform: scale(1);
  }

  100% {
    margin-top: -5px;
    height: 80px;

    transform: scale(0.5);
  }
}
</style>
